<template>
  <component :is="tag" prefer-lane-title :data-lane="lane">
    <span v-if="lane === 'AD_CARRY'">AD</span>
    <span v-else>{{ lane }}</span>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h3',
    },
    lane: {
      type: String,
      default: 'TOP',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[prefer-lane-title] {
  .c(@c-gray-600);.chakra;.fs(18);.lh(23);.flex;.items-center;.h(23);
  &:before {content: '';.wh(24);display: inline-block;.mr(4);background-size: 24px;}
  &[data-lane='ALL']:before {.bg('@{img}/coaching/lol-line-all-gray.svg');}
  &[data-lane='JUNGLE']:before {.bg('@{img}/coaching/lol-line-jungle-gray.svg');}
  &[data-lane='SUPPORT']:before {.bg('@{img}/coaching/lol-line-support-gray.svg');}
  &[data-lane='MID']:before {.bg('@{img}/coaching/lol-line-mid-gray.svg');}
  &[data-lane='TOP']:before {.bg('@{img}/coaching/lol-line-top-gray.svg');}
  &[data-lane='AD_CARRY']:before {.bg('@{img}/coaching/lol-line-bottom-gray.svg');}
}
</style>

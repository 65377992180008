<template>
  <div line-badge :data-line="lane">
    <input class="lane-checkbox" type="checkbox" v-model="v" v-if="checkbox" :id="internalId" :value="lane" :disabled="disabled" />
    <label :for="internalId" class="lane-display">
      <img :src="iconUrl" />
      <p>{{ text }}</p>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    lane: {
      type: String,
      default: 'ALL',
      validator(v) {
        return ['TOP', 'MID', 'AD_CARRY', 'JUNGLE', 'SUPPORT', 'ALL', 'NONE'].includes(v);
      },
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '', // 여러개의 체크박스를 그룹별로 구분하고자 할 때 사용.
    },
    value: {
      // lane[]
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalId() {
      return `${this.id}-${this.lane}`;
    },
    v: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    text() {
      return {
        TOP: '탑',
        MID: '미드',
        AD_CARRY: '원딜',
        JUNGLE: '정글',
        SUPPORT: '서포터',
      }[this.lane] ?? '-';
      // return this.$t(`coaching.MC_lol_position_${this.lane.toLowerCase()}`);
    },
    iconUrl() {
      return `/img/coaching/${this.disabled ? 'lol-line-disable' : 'lol-line-black'}/${this.lane}.png`;
    },
    tag() {
      return this.checkbox ? 'label' : 'div';
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-text-gray: #777;
[line-badge] { display: inline-block;
  .lane-display {
    .p(8);.c(@c-black-primary);border: solid 1px @c-black-primary;.h(48);display: inline-flex;.items-center;.flex-jc(center);.br(4);
    transition: color .3s ease-in-out, border .3s ease-in-out;
  }
  // 체크박스 있을 때
  &:not(.hover-test) .lane-checkbox:not(:checked):not(:disabled) ~ .lane-display {
    &:not(:hover) {border-color: @c-weak-gray;.c(@c-text-gray);}
  }
  .lane-display > img { .wh(32);.mr(8); display: inline-block; }
  .lane-display > p { display: inline-block;.fs(14);.lh(14);font-weight: 700;}

  .lane-checkbox {.hide}
  // 선택이나 disabled 되지 않은 디폴트 상태
  .lane-checkbox:not(:checked):not(:disabled) ~ .lane-display {cursor: pointer;}
  .lane-checkbox:checked ~ .lane-display { border-color: @blue-primary; border-width: 3px; .c(@blue-primary); }
  .lane-checkbox:disabled ~ .lane-display { border-color: @gray-200; .c(@gray-200)}
  .lane-checkbox:disabled:checked ~ .lane-display { border-color: @blue-primary; border-width: 3px; .c(@blue-primary);}
  & + [line-badge] {.ml(8)}

  // 기획 요청에 따라
  // 1개 이하일 때 선택을 해제할 수 없도록
  // 2개 이상일 때 선택을 추가로 할 수 없도록
  // disabled는 스타일이 겹쳐서 적당하지 않으므로 내부적으로 처리
  // CoachDetailIntroduction 참조
  &[data-length='1'] {
    .lane-checkbox:checked ~ .lane-display { pointer-events: none;}
  }
  &[data-length='2'], &[data-length='3'], &[data-length='4'], &[data-length='5'] {
    .lane-checkbox:not(:checked) ~ .lane-display { pointer-events: none;}
  }
}
</style>

<template>
  <div :class="{error: hasError}" class="wrap-textarea" memo-text-area :data-theme="theme">
    <p class="input-info" v-html="infoText"></p>
    <textarea ref="textarea" :disabled="disabled" :placeholder="placeholder"
      :value="value" @blur="onBlur" @focus="onFocus" @input="onInput" />
  </div>
</template>

<script>
// import ColorButton from '@shared/components/common/ColorButton';
export default {
  components: { /* ColorButton */ },
  data: () => ({
    focused: false,
  }),
  props: {
    value: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: 10,
    },
    maxLength: {
      type: Number,
      default: 2000,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '내용을 입력해주세요.',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    textLength() {
      return this.value?.length || 0;
    },
    infoText() {
      if (this.textLength > 1 || this.minLength === 0) {
        return `<em>${this.textLength}</em>/${this.maxLength}`;
      }
        return `<em>${this.textLength}</em>/${this.maxLength} (최소 ${this.minLength}자)`;
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.$nextTick(() => {
        this.$refs.textarea.focus();
        this.$emit('focus');
      });
    },
    onBlur() {
      if (this.value === '' && !this.editing) {
        this.focused = false;
        this.$emit('blur');
      }
    },
    onInput(ev) {
      this._value = ev.target.value;
    },
    onTipClick() {
      this.$refs.textarea.focus();
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[memo-text-area] {.rel;
  > textarea {.w(100%);.bgc(#fff);.fs(14);}
  > .control {text-align: right;
    [color-button] + [color-button] {.ml(8)}
  }
  .pb(30);.h(235);
  border: 1px solid #d5d5de;
  .br(16);
  .p(16, 5, 16, 16);
  > textarea {.scroll;.h(183);}
  .input-tip {.abs;.p(10, 12, 9, 12);.c(#a4a3ae);}
  .input-tip > li { .ml(13);.rel;.lh(20.72);.fs(14)}
  .input-tip > li::before {content: '';.bgc(#a4a3ae);.wh(4, 4);.br(100%);.abs;.l(-12);.t(8)}
  .input-info {.abs;.b(14);.r(16);.c(#a4a3ae);.fs(14);.lh(14)}
  em {.c(#191919)}
  &:hover {border: 1px solid #191919;}
  &:focus-within {border: 1px solid #2c81ff;}
  &.error {
    border: 1px solid #f00;
    .input-info {.c(#f00);}
    em {.c(#f00)}
  }
  &[data-theme='base-alt'] {
    .br(4);
  }
}
</style>

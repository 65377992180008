<template>
  <div prefer-champs>
    <div v-for="(champs, lane) in champsByLane" :data-lane="lane" :key="lane" class="lane">
      <prefer-lane-title :lane="lane" />
      <prefer-champ v-for="champ in champs" :prefer-champ="champ" :key="`${lane}-${champ.champion.championId}`" disabled />
      <div class="edit-buttons" v-if="editMode">
        <button class="reset" @click="resetLane(lane)">초기화</button>
        <!-- lane === NONE 인 경우에는 새롭게 챔프를 추가해서는 안됨 -->
        <button class="add" @click="addChampToLane(lane)" v-if="lane !== 'NONE'">추가</button>
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import PreferChamp from '@/views/components/coaching/PreferChamp.vue';
import PreferLaneTitle from '@/views/components/coaching/coach-detail/PreferLaneTitle.vue';
import { ModalFilterMultiChamp } from '@/views/components/LazyLoadings';

export default {
  components: { PreferLaneTitle, PreferChamp },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _preferChamps() {
      return /** @type{PreferChamp[]} */ this.value;
    },
    champsByLane() {
      const cl = {};
      // 편집 모드에서는 해당 포지션에 챔프가 없어도 모든 포지션이 표기되어야 함
      if (this.editMode) ['TOP', 'MID', 'AD_CARRY', 'JUNGLE', 'SUPPORT'].forEach(lane => cl[lane] = []);
      this._preferChamps.forEach(preferChamp => {
        // 개발 환경에서는 드물게 lane 정보가 아예 없는 경우도 있었음. 리뷰 및 디버깅을 위해 추가
        if (preferChamp?.lanes?.length === 0) cl.NONE = [...(cl.NONE || []), preferChamp];
        preferChamp.lanes.forEach(lane => {
          cl[lane] = [...(cl[lane] || []), preferChamp];
        });
      });
      return cl;
    },
    // 새롭게 챔프 추가할 때 챔프 상세 정보 가져오기 위해서 사용
    champsByCode() {
      return this.$store?.getters?.['lol/champsByCode'] ?? new Map();
    },
  },
  methods: {
    // vee-validation은 v-model이 있어야 자연스럽게 동작하므로 v-model을 이용하여 구현
    resetLane(lane) {
      const newPCs = /** @type{PreferChamp[]} */ _cloneDeep(this._preferChamps); // champ 기준으로 자료 구성
      newPCs.forEach(champ => {
        if (!champ.lanes) champ.lanes = [];
        // 챔프 정보에 lane이 있을 경우 제거
        champ.lanes = champ.lanes.filter(champLane => champLane !== lane);
      });
      // lane 정보가 없는 챔프 정보는 삭제
      const result = newPCs.filter(champ => champ?.lanes?.length >= 1);
      this.$emit('input', result);
    },
    async addChampToLane(lane) {
      const champsByLane = this.champsByLane[lane] || [];
      const defaultChampIdList = champsByLane.map(c => c?.champion?.code);
      let selectedChampCodes = /** @type{string[]} */ [];
      try {
        selectedChampCodes = await this.$modal(ModalFilterMultiChamp, { defaultChampIdList });
      } catch (e) {
        // 모르는 유형의 에러는 throw
        if (e !== 'no_champ') throw e;
      }
      // preferChamps
      const newPCs = /** @type{PreferChamp[]} */ _cloneDeep(this._preferChamps); // champ 기준으로 자료 구성
      // 챔프 목록이 map이 아니고 배열이므로 빠른 순환을 위해 미리 챔프 인덱스 생성
      // { [챔프코드]: 숫자 }
      const champIndices /** @type{Record<string,number>} */ = newPCs.reduce((ac, cv, i) => ({ ...ac, [cv?.champion?.code]: i }), {});
      // 새롭게 선택된 챔프 코드
      selectedChampCodes.forEach(champCode => {
        const champIndex = champIndices[champCode];
        // 챔프가 기존에 존재하지 않을 경우 새롭게 추가
        if (champIndex === undefined) {
          newPCs.push({ champion: this.champsByCode.get(champCode), lanes: [lane] });
        }
        // 챔프가 기존에 존재할 경우
        if (champIndex !== undefined) {
          if (newPCs[champIndex]?.lanes === undefined) newPCs[champIndex].lanes = [];
          // 새롭게 추가하고 중복 제거
          newPCs[champIndex].lanes = Array.from(new Set([...(newPCs ?? [])[champIndex].lanes, lane]));
        }
      });
      this.$emit('input', newPCs);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-champ-desc: #444;
[prefer-champs] {
  [prefer-lane-title] {.mb(8.5)}
  .lane {.min-h(100);}
  .lane + .lane {.mt(20);
    &:before {.wh(100%, 1);border-top: solid 1px @c-gray-100;content: '';.block;.mb(20);}
  }
  [prefer-champ] {.mr(12);.w(52);}
  [prefer-champ] img {.br(6);.wh(52);.mb(4);object-fit: contain;border: none;}
  [prefer-champ] .label-text { .c(@c-champ-desc); }
  .edit-buttons { .ib; .fr;
    .add {.c(#191919);.fs(12, 12); .p(20);.mr(4); .bold;}
    .reset {.c(#ddd); .fs(12.12);}
  }
}
</style>

<template>
  <div coach-detail-introduction>
    <client-only>
      <div class="top-group" v-if="isEditable">
        <basic-button class="btn-edit" :theme="'white'" v-if="!isEditMode" @click="onEdit">
          <svg-pencil class="pencil-icon" />
          코치님 소개 편집하기
        </basic-button>
        <basic-button class="btn-update" :theme="'blue'" v-if="isEditMode" @click="updateCoachInfo">
          저장하기
        </basic-button>
        <basic-button class="btn-cancel" :theme="'white'" v-if="isEditMode" @click="onCancel">
          취소하기
        </basic-button>
      </div>
    </client-only>
    <client-only>
      <div class="edit-intro" v-if="isEditable && isEditMode">
        <validation-observer ref="validator" class="coach-form" @submit="updateCoachInfo">
          <div class="edit-image">
            <Heading>이미지</Heading>
            <p class="upload-description">권장 사이즈 816x459(가로형)</p>
            <coach-image-selector v-model="coachImages" />
          </div>

          <validation-provider rules="youtubeUrl" name="coachPromotionVideoUrl" v-slot="{errors, passed}">
            <Heading>소개영상</Heading>
            <text-input-alt class="input-youtube-url" v-model="coachPromotionVideoUrl" placeholder="유튜브 링크를 입력해주세요." :errors="errors" :passed="passed">
              <template #error>
                <validation-errors :errors="errors" />
              </template>
              <template #passed>
                <video-embed v-if="youtubeEmbedUrl.length > 0" :url="youtubeEmbedUrl" class="preview-top-content" />
              </template>
            </text-input-alt>
          </validation-provider>

          <validation-provider class="edit-desc" rules="required|lengthMin:10|lengthMax:1000" name="coachDescription" v-slot="{errors}">
            <Heading>소개
              <template v-slot:sub>(필수 입력)</template>
            </Heading>
            <MemoTextArea v-model="description" :min-length="10" :max-length="1000" class="memo-input" :hasError="!!errors.length" theme="base-alt" />
            <validation-errors :errors="errors" />
          </validation-provider>

          <div class="edit-history">
            <div class="history-title-group">
              <Heading>경력
                <template v-slot:sub>최소 1개</template>
              </Heading>
              <basic-button class="btn-add-history" @click="addHistory()" theme="grey" :disabled="mainHistoryExceeded">
                <img src="/img/icon/icon-plus.svg" alt="plus" class="icon-plus" />
                <span>추가하기</span>
              </basic-button>
            </div>
            <validation-provider v-for="(_, id) in mainHistory" :key="id" class="history-input" tag="div" :vid="`${id}`" v-slot="{errors}" rules="required" name="coachHistory" immediate>
              <text-input-alt v-model="mainHistory[id]" :tabindex="`${id+1}`" placeholder="경력 사항을 입력해 주세요." ref="historyInput" :errors="errors">
                <template #error>
                  <validation-errors :errors="errors" />
                </template>
              </text-input-alt>
              <basic-button class="btn-remove-history" @click="removeHistory(id)" theme="grey" :color-only="false" :disabled="mainHistory.length < 2">
                <img src="/img/icon/icon-minus.svg" alt="plus" class="icon-minus" />
              </basic-button>
            </validation-provider>
          </div>
          <validation-provider class="edit-position" tag="div" name="preferLolLanes" v-slot="{errors}" rules="lolLanes:1,2" immediate>
            <Heading>특화 포지션
              <template v-slot:sub>최소 1개/최대 2개</template>
            </Heading>
            <validation-errors :errors="errors" />
            <line-badge v-model="positions" v-for="lane in allPositions"
              :checkbox="true" :lane="lane" :key="lane"
              :data-length="positions.length"
            />
          </validation-provider>
          <validation-provider class="edit-champion" tag="div" name="preferLolChamps" v-slot="{errors}" rules="preferChamps:1,3" immediate>
            <Heading>특화 챔피언
              <template v-slot:sub>최소 1개/최대 포지션별 3개</template>
            </Heading>
            <validation-errors :errors="errors" />
            <prefer-champs edit-mode v-model="preferChamps" />
          </validation-provider>
        </validation-observer>
      </div>
    </client-only>
    <div v-if="!isEditMode">
      <video-embed :title="coachPromotionVideoTitle" :url="coachPromotionVideoUrl" v-if="coachPromotionVideoUrl" class="top-content" />
      <cdn-img :src="historyThumbnail" alt="코치 대표 이미지" class="coach-image top-content" v-if="historyThumbnail !== ''" />
      <section-container v-if="description !== ''" theme="coach-detail">
        <Heading>소개</Heading>
        <pre class="description">{{ description }}</pre>
      </section-container>
      <section-container v-if="hasMainHistory" theme="coach-detail">
        <Heading>경력</Heading>
        <ul class="history">
          <li v-for="(history,i) in mainHistory" :key="i">
            <span>{{ history }}</span>
          </li>
        </ul>
      </section-container>
      <section-container v-if="positions.length >= 1" theme="coach-detail">
        <Heading>특화 포지션</Heading>
        <line-badge v-for="lane in positions" :lane="lane" :key="lane" />
      </section-container>
      <section-container v-if="hasPreferChamps" theme="coach-detail">
        <Heading>특화 챔피언</Heading>
        <prefer-champs :value="preferChamps" />
      </section-container>
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { getter } from 'shared/utils/storeUtils';
import Heading from '@/views/components/common/text/Heading.vue';
import LineBadge from '@/views/components/coaching/coach-detail/LineBadge.vue';
import PreferChamps from '@/views/components/coaching/coach-detail/PreferChamps.vue';
import VideoEmbed from '@/views/components/coaching/VideoEmbed.vue';
import SectionContainer from '@/views/components/coaching/SectionContainer.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import TextInputAlt from '@/views/components/coaching/TextInputAlt.vue';
import MemoTextArea from '@/views/components/common/input/MemoTextArea.vue';
import CoachImageSelector from '@/views/components/coaching/coach-detail/CoachImageSelector.vue';
import floatPopIn from '@/mixins/floatPopIn';
import FloatEditAreaIntro from '@/views/components/coaching/coach-detail/FloatEditAreaIntro.vue';
import SvgPencil from '@/views/graphics/svg-pencil.vue';

export default {
  name: 'CoachDetailIntroduction',
  components: { SvgPencil, CoachImageSelector, BasicButton, TextInputAlt, MemoTextArea, SectionContainer, VideoEmbed, PreferChamps, LineBadge, Heading, CdnImg },
  mixins: [floatPopIn()],
  data: () => ({
    isEditMode: false,
    newCoachInfo: /** @type {CoachDetail} */ {},
  }),
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.newCoachInfo = _cloneDeep(this.coachInfo);
  },
  computed: {
    preferChamps: {
      get() {
        return /** @type{PreferChamp[]} */ this.ci?.champions ?? [];
      },
      set(v) {
        this.$set(this.newCoachInfo, 'champions', v);
      },
    },
    ci() {
      return /** @type{CoachDetail} */ this.isEditMode ? this.newCoachInfo : this.coachInfo;
    },
    myCoachId: getter('auth', 'myCoachId'),
    coachId() {
      return this.ci?.coachId ?? '';
    },
    mainHistory: {
      get() {
        return /** @type{string[]} */ this.ci?.mainHistory ?? [];
      },
      set(v) {
        this.$set(this.newCoachInfo, 'mainHistory', v);
      },
    },
    description: {
      get() {
        if (this.isEditMode) return this.ci?.description ?? '';
        // 기획서에 따라 기본 메시지 별도 지정
        return this.ci?.description ?? `안녕하세요 ${this.ci?.user?.nickname} 입니다`;
      },
      set(v) {
        this.$set(this.newCoachInfo, 'description', v);
      },
    },
    positions: {
      get() {
        return this.ci?.positions ?? [];
      },
      set(v) {
        this.$set(this.newCoachInfo, 'positions', v);
      },
    },
    allPositions() {
      return ['TOP', 'MID', 'AD_CARRY', 'JUNGLE', 'SUPPORT'];
    },
    historyThumbnail: {
      get() {
        return this.ci?.historyThumbnail ?? '';
      },
    },
    coachImages: {
      // 인풋 컴포넌트가 행렬만 지원하여 자료형을 행렬로 변형
      get() {
        const thumbnail = this.ci?.historyThumbnail ?? '';
        return thumbnail === '' ? [] : [thumbnail];
      },
      set(v = []) {
        const url = v?.[0] ?? '';
        this.$set(this.newCoachInfo, 'historyThumbnail', url);
      },
    },
    coachPromotionVideoUrl: {
      get() {
        return this.ci?.coachPromotionVideo?.contentUrl ?? '';
      },
      set(v) {
        if (!this.newCoachInfo.coachPromotionVideo) this.$set(this.newCoachInfo, 'coachPromotionVideo', {});
        this.$set(this.newCoachInfo.coachPromotionVideo, 'contentUrl', v);
      },
    },
    coachPromotionVideoTitle: {
      get() {
        return this.ci?.coachPromotionVideo?.title ?? '';
      },
      set(v) {
        if (!this.newCoachInfo.coachPromotionVideo) this.$set(this.newCoachInfo, 'coachPromotionVideo', {});
        this.$set(this.newCoachInfo.coachPromotionVideo, 'title', v);
      },
    },
    hasPreferChamps() {
      // 개발 환경에서는 preferChamp에 lane데이터가 없는 경우도 있었음!
      return Array.isArray(this.preferChamps) && this.preferChamps?.length >= 1;
    },
    hasMainHistory() {
      return this.mainHistory.length >= 1;
    },
    mainHistoryExceeded() {
      return this.ci?.mainHistory?.length >= 20;
    },
    youtubeEmbedUrl() {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = this.coachPromotionVideoUrl.match(regExp);
      const videoId = (match && match[2].length === 11) ? match[2] : null;
      if (videoId != null) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
        return '';
    },
  },
  methods: {
    onEdit() {
      this.newCoachInfo = _cloneDeep(this.coachInfo);
      this.isEditMode = true;
    },
    onCancel() {
      this.isEditMode = false;
    },
    async updateCoachInfo(ev) {
      if (ev) ev.preventDefault();
      const isValid = await this.$refs.validator.validate();
      if (!isValid) return;
      const data = _pick(this.newCoachInfo, ['historyThumbnail', 'description', 'mainHistory', 'positions']);
      data.champions = this.preferChamps.map(each => ({ 'championCode': each.champion.code, 'lanes': each.lanes }));
      if (this.youtubeEmbedUrl !== '') {
        if (!data.coachPromotionVideo) data.coachPromotionVideo = {};
        if (!data.coachPromotionVideo.title) data.coachPromotionVideo.title = '';
        data.coachPromotionVideo.createdDatetime = Date.now();
        data.coachPromotionVideo.contentUrl = this.youtubeEmbedUrl;
      }
      const coachInfo = await this.$services.coaching.modifyCoachIntroduction(this.ci.coachId, data);
      if (coachInfo) this.$emit('update-coach-info', coachInfo);
      this.isEditMode = false;
    },
    async uploadImage({ file, resolve }) {
      const { key: image } = await this.$services.aws.upload(file);
      resolve();
      this.info.historyThumbnail = image;
    },
    addHistory() {
      const { mainHistory } = this;
      if (this.mainHistoryExceeded) return;
      mainHistory.splice(mainHistory.length + 1, 0, '');
      this.$set(this.ci, 'mainHistory', mainHistory);
      this.onFocus();
    },
    removeHistory(id) {
      this.ci.mainHistory?.splice(id, 1);
      this.onFocus();
    },
    async onFocus() {
      await this.$nextTick();
      const index = this.mainHistory.length - 1;
      const focusComponent = this.$refs.historyInput[index];
      focusComponent?.$el.querySelector('input').focus();
    },
    getFloatPopInOptions() {
      return ({
        componentFloat: FloatEditAreaIntro,
        // data 내부의 prop이 dynamic하게 반영되지 않으므로, 대신 Vue mutation Observer가 붙어있는 data를 통째로 내려버림
        optionsFloat: { onEdit: this.onEdit.bind(this), onCancel: this.onCancel.bind(this), onSave: this.updateCoachInfo.bind(this), data: this.$data },
        unregister: this.myCoachId !== this.coachId,
      });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-detail-introduction] {.noto;.pt(32);
  .top-content {.bgc(@c-black-primary);.w(100%);.min-h(473)}
  .coach-image { object-fit: cover;.br(8);}
  > [video-embed] {.h(473);}

  .top-content + .top-content {.mt(12);}
  [section-container] + [section-container] {.mt(48);}
  .top-content + [section-container] {.mt(48);}
  pre.description {white-space: pre-wrap; .fs(16);.lh(24)}

  [heading] {.mb(29);}

  .history {.ul-disc-style();}
  .edit-buttons { .flex-wrap; flex-direction: row-reverse;}
  .edit-intro { .w(100%);
    .edit-image { .mt(20); .mb(44);}
    .preview-top-content { .min-h(200); }
    .upload-description { .c(#777); .fs(14, 21); .mb(5);}
    .input-youtube-url {.max-w(320); .mb(40)}
    .input-coach-description {.mb(40)}
    .edit-history {.mb(44);}
    .edit-position {.mb(44);}
    .edit-champion {.mb(44);}
    .history-input {.flex; .space-between;.mb(12);
      [text-input-alt] {flex: 1;.mr(8)}
    }
    .btn-add-history {.abs;.r(0);.ib; .w(81); .h(34); .fs(12, 16); .p(0); .c(#444)}
    .btn-remove-history {.wh(30, 30); .p(0); .mt(10);}
    .icon-plus {.wh(12, 12); .mr(5);}

    [image-selector] { .wh(160, 200); .bgc(@c-base-gray); .mb(21);
      label .selected-image { .hf; object-fit: cover; }
      img[alt='edit'] { .pt(calc(100% - 20px)); }
      label::after {.contain('@{img}/icon/ico_edit.svg');content: '';.rb(0, 0);.wh(40, 40);.abs;.block;.pointer;}
    }
  }
  .edit-desc {
    [validation-errors] {.c(#f00);.mt(8);}
  }
  .edit-desc {.mb(44)}
  .edit-position, .edit-champion {
    [validation-errors] {.c(#f00);.mb(8);}
  }
  .history-title-group {.flex;}
  .top-group {.flex;flex-direction: row-reverse;
    .btn-edit { .mb(32); .w(264);
      .pencil-icon {.wh(20); .mr(6);}
    }
    .btn-update {.fr; .w(135);}
    .btn-cancel {.fr; .mr(12); .w(135);}
  }

  @media (@tp-down) {
    .pt(24);
    pre.description {.fs(14);.lh(21)}
    [line-badge] { .m(3); }
    .top-group {.hide}
  }
}
</style>

<template>
  <label prefer-champ :class="{checked: v}">
    <input type="checkbox" v-model="v" :disabled="disabled" />
    <span class="checkmark" />
    <cdn-img :src="src" :alt="code" />
    <span class="label-text">{{ koName }}</span>
  </label>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';

/**
 * @description
 * 체크박스 형식으로 선택이 가능한 선호 챔프 목록 표기용 아이콘
 */
export default {
  name: 'PreferChamp',
  components: { CdnImg },
  props: {
    preferChamp: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    c() {
      return /** @type{PreferChamp} */ this.preferChamp;
    },
    src() {
      return this.c?.champion?.imageUrl || '';
    },
    code() {
      return this.c?.champion?.code || '';
    },
    koName() {
      return this.c?.champion?.koName || '';
    },
    v: {
      get() {
        return this.value;
      },
      set(v) {
        if (this.disabled) return;
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[prefer-champ] {.rel;display: inline-block;.w(56);
  [cdn-img] {.wh(56, 56);.br(20);border: solid 2px transparent;.mb(6);.block;.rel;}
  > input { .abs;.lt(0, 0);.wh(100%, 100%);.o(0);}
  > .label-text {.fs(11);.lh(11);.c(#787781);text-align: center;.block;white-space: pre-wrap;}
}
[prefer-champ] > input:checked {
  & ~ [cdn-img] {border: solid 2px #8300ff;}
  & ~ .label-text {.c(#8300ff)}
  & ~ .checkmark:after {.abs;content: '';display: inline-block;.wh(16, 16);.bgc(#8300ff);.br(100%);.lt(-4, 40);.z(1);.contain('/img/extra/ico_check_white_bold.svg');background-position: center;background-size: 12px 12px;}
}
</style>

<template>
  <div video-embed :data-theme="theme">
    <iframe
      v-if="url !== ''"
      width="100%"
      height="100%"
      controls="0"
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      :src="src"
      :title="title"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'VideoEmbed',
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    src() {
      return `${this.url}?autoplay=1&amp;loop=1&amp;mute=1`;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[video-embed] {overflow: hidden;.rel;
  > iframe { .abs;.wh(100%);.block;}
  &[data-theme='base'] {.br(8);
    // border-radius iOS fix
    clip-path: inset(0% 0% 0% 0% round 8px);
  }
  &[data-theme='no-round'] {}
}
</style>

<template>
  <!-- 코치 상세 페이지 코치용 편집시 모바일 화면에서 플로팅으로 편집 확정하는 기능 -->
  <!-- 소개 페이지용 -->
  <div float-edit-area-intro>
    <template v-if="!data.isEditMode">
      <basic-button theme="white" @click="onEdit">
        <svg-pencil />
        코치님 소개 편집하기
      </basic-button>
    </template>
    <template v-if="data.isEditMode">
      <basic-button theme="white" @click="onCancel">취소하기</basic-button>
      <basic-button theme="blue" @click="onSave">저장하기</basic-button>
    </template>
  </div>
</template>
<script>
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import SvgPencil from '@/views/graphics/svg-pencil.vue';

export default {
  components: { SvgPencil, BasicButton },
  props: {
    data: {},
    onEdit: {},
    onCancel: {},
    onSave: {},
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[float-edit-area-intro] {.flex;
  [basic-button] {flex: 1;.h(48);}
  [basic-button] svg {.mr(6)}
  [basic-button] + [basic-button] {.ml(8)}
}
</style>
